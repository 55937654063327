import React from 'react'
import './Category.css'
import vage from '../Assets/puja_12621921.png'
import fruit from '../Assets/dish_857718.png'


const Category = () => {
  return (
    <div className='category'>
        <h1>Category</h1>
        <div className='category-container'>
            <div className='cate-icon'>
                <img src={vage} alt="img"/>
                <p>Pooja Samagiri</p>   
            </div>
            <div className='cate-icon'>
                <img src={fruit} alt="img"/>
                <p>Food Items</p> 
            </div>
        </div>
    </div>
  )
}

export default Category