import React, { useEffect, useState } from "react";
import "./Product.css";
import axios from "axios";
import { Link } from "react-router-dom";
import {toast} from 'react-hot-toast'
const Product = () => {
  const [products, setProducts] = useState([]);
  const [totalProduct, setTotalProduct] = useState(9);

  const [quantities, setQuantities] = useState(Array(totalProduct).fill(1));
  const handleNumber = (e, index) => {
    const value = parseInt(e.target.value, 10);

    if (isNaN(value) || value < 1 || value > 50) {
        // Handle invalid input, e.g., show an error message
        console.error('Invalid quantity input');
    } else {
        // Update the quantities array with the new value for the specific index
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = value;
            return newQuantities;
        });
    }
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://foodbackend21.onrender.com/auth/All-product"
        );
        setProducts(response.data.products);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleTocart = (id, count) => {
    if (id && count !== undefined) {
      const selectedProduct = products.find((product) => product._id === id);
      const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

      const existingProductIndex = existingCart.findIndex(
        (item) => item.productId === id
      );

      if (existingProductIndex !== -1) {
        existingCart[existingProductIndex].quantity += count;
        toast.success("Item Already Present In The Cart We increase the Quantity")

      } else {
        const newProduct = {
          productId: id,
          title: selectedProduct.title,
          Price: selectedProduct.mrpPrice,
          img: selectedProduct.imageUrls,
          quantity: count,
        };

        existingCart.push(newProduct);
      }
      toast.success("Item Added To Cart")
      localStorage.setItem("cart", JSON.stringify(existingCart));
      console.log(existingCart);
    } else {
      console.error("Invalid id or count is undefined");
      toast.error("Item Added To Cart")

    }
  };

  

  return (
    <div className="product">
      <h1>Product Page</h1>
      <div className="product-container">
        {products.slice(0, totalProduct).map((item, index) => (
          <div key={index} className="product-item">
            <div className="product-img">
              <img src={item.imageUrls} alt="img" />
            </div>
            <div className="product-detail">
              <h4>{item.title}</h4>
              <p>
                <del>{item.mrpPrice}</del> <br />
                {item.discountedPrice}
              </p>
              <div className="product-add">
            <input
                type="number"
                value={quantities[index] || ''}
                onChange={(e) => handleNumber(e, index)}
                name="quantity"
                min="1" max="50"
            />
            <button onClick={() => handleTocart(item._id, quantities[index])}>
                <i className="ri-shopping-bag-fill"></i>
            </button>
        </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
