import React, { useState } from "react";
import "./Pooja.css";
import { Link } from "react-router-dom";
import {toast} from 'react-hot-toast'
const Poojasamagiri = () => {
  const [totalProduct, setTotalProduct] = useState(10);
  const [quantities, setQuantities] = useState(Array(totalProduct).fill(1));
  const Mypoojaproduct = [
    {
      id: 1,
      imageUrls:
        "https://www.saveur.com/uploads/2022/03/05/sugarcane-linda-xiao.jpg?auto=webp&width=2000&height=1333",
      title: "Kush",
      mrpPrice: 12,
      discountprice: 10,
      detail:'The white flesh of sugarcanes with sharp fibres are chewy, juicy and sweet to taste'
    },

    {
        id: 2,
        imageUrls:
          "https://assets.ajio.com/medias/sys_master/root/20220215/Mj07/620b7560f997dd03e2d0bba5/-473Wx593H-461638390-white-MODEL.jpg",
        title: "Dhoti",
        mrpPrice:600,
        discountprice:500,
        detail:'White solid dhoti pants with pleats, has a waistband with drawstring closure'
    },
    {
        id: 3,
        imageUrls:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTM3tVDUO0jEdFGjPJDQyr8ncVft4hbpgtPfg&usqp=CAU",
        title: "Paag",
        mrpPrice:160,
        discountprice:150,
        detail:'Mithila Shahi Mithila Paag Pink/Kopti Mithilaanchal Paag '
    },
    {
        id: 4,
        imageUrls:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_IC2oV_kZiD6vypWnrMt0C2NPB3f2HyX9Mg&usqp=CAU",
        title: "Janeu",
        mrpPrice: 110,
        discountprice:100,
        detail:'Traders White Thread for Pooja, Janeu Thread for Men, Cotton Janeu for Puja '
    },
    {
      id:5,
      imageUrls:"https://i.ibb.co/X24hhNM/nn.png",
      title:"Doob",
      mrpPrice:300,
      discountprice:250,
      detail:'Havan Samidha Doob Ghaas Durva Grass Doob Grass for Puja and Havan'
  },
  {
    id:10,
    imageUrls:"https://i.ibb.co/y8BGg8h/aa.png",
    title:"Aam pallav",
    mrpPrice:20,
    discountprice:10,
    detail:'आम का पत्ता इनका व्यापक रूप से घरों में कार्यों और शुभ समारोहों के लिए उपयोग किया जाता है'
},
  ];
  const handleNumber = (e, index) => {
    const value = parseInt(e.target.value, 10);

    if (isNaN(value) || value < 1 || value > 50) {
        // Handle invalid input, e.g., show an error message
        console.error('Invalid quantity input');
    } else {
        // Update the quantities array with the new value for the specific index
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = value;
            return newQuantities;
        });
    }
};
const handleTocart = (id, count) => {
  if (id && count !== undefined) {
    const selectedProduct = Mypoojaproduct.find((product) => product.id === id);
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

    const existingProductIndex = existingCart.findIndex(
      (item) => item.productId === id
    );

    if (existingProductIndex !== -1) {
      existingCart[existingProductIndex].quantity += count;
      toast.success("Item Already Present In The Cart We increase the Quantity")

    } else {
      const newProduct = {
        productId: id,
        title: selectedProduct.title,
        Price: selectedProduct.mrpPrice,
        img: selectedProduct.imageUrls,
        quantity: count,
      };

      existingCart.push(newProduct);
    }
    toast.success("Item Added To Cart")
    localStorage.setItem("cart", JSON.stringify(existingCart));
    console.log(existingCart);
  } else {
    console.error("Invalid id or count is undefined");
    toast.error("Item Added To Cart")

  }
};  
  return (
    <div className="product">
      <h1>Pooja Samagiri</h1>
      <div className="product-container">
        {Mypoojaproduct.map((item, index) => (
          <div key={index} className="product-item">
            <div className="product-img">
              <img src={item.imageUrls} alt="img" />
            </div>
            <div className="product-detail">
              <h4>{item.title}</h4>
              <div><p>{item.detail}</p></div>
              <p className='price'>
              <del>₹{item.mrpPrice}</del> 
              ₹{item.discountprice} per pc
            </p>
              <div className="product-add">
              <input
                type="number"
                value={quantities[index] || ''}
                onChange={(e) => handleNumber(e, index)}
                name="quantity"
                min="1" max="50"
            />
              <button onClick={() => handleTocart(item.id, quantities[index])}>
                <i className="ri-shopping-bag-fill"></i>
            </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Poojasamagiri;
