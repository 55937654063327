import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Delivery.css';

const Delivery = () => {
  const [order, setOrder] = useState({
    shippingInfo: {
      name: "",
      address: "",
      city: "",
      country: "",
      pinCode: "",
      landmark: "",
      transactionId: ""
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("YOUR_API_ENDPOINT_HERE");
        setOrder(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrder(prevOrder => ({
      ...prevOrder,
      shippingInfo: {
        ...prevOrder.shippingInfo,
        [name]: value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("YOUR_API_ENDPOINT_HERE", order);
      console.log('Order submitted successfully:', response.data);
    } catch (error) {
      console.log('Error submitting order:', error);
    }
  };

  return (
    <div className='delivery'>
      <div className='delivery-container'>
        <h2>Delivery <span>Form</span></h2>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>Name:</label>
            <input
              type="text"
              name='name'
              value={order.shippingInfo.name}
              onChange={handleChange}
              className='form-control'
            />
          </div>

          <div className='form-group'>
            <label>Address:</label>
            <input
              type="text"
              name='address'
              value={order.shippingInfo.address}
              onChange={handleChange}
              className='form-control'
            />
          </div>

          <div className='form-group'>
            <label>City:</label>
            <input
              type="text"
              name='city'
              value={order.shippingInfo.city}
              onChange={handleChange}
              className='form-control'
            />
          </div>

          <div className='form-group'>
            <label>Country:</label>
            <input
              type="text"
              name='country'
              value={order.shippingInfo.country}
              onChange={handleChange}
              className='form-control'
            />
          </div>

          <div className='form-group'>
            <label>Pincode:</label>
            <input
              type="text"
              name='pinCode'
              value={order.shippingInfo.pinCode}
              onChange={handleChange}
              className='form-control'
            />
          </div>

          <div className='form-group'>
            <label>Landmark:</label>
            <input
              type="text"
              name='landmark'
              value={order.shippingInfo.landmark}
              onChange={handleChange}
              className='form-control'
            />
          </div>

          <div className='form-group'>
            <label>Transaction ID:</label>
            <input
              type="text"
              name='transactionId'
              value={order.shippingInfo.transactionId}
              onChange={handleChange}
              className='form-control'
            />
          </div>

          <button className='button' type='submit'>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Delivery;
