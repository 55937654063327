import React from "react";
import './About.css'
import chatimg from '../Assets/Untitled design_20240215_174912_0000.png'

const About = () => {
  return (
    <div className="about">
      <h1>Aboutn Us</h1>
      <div className="about-containr">
        <div className="about-left">
            <img src={chatimg } alt="img" />
        </div>
        <div className="about-right">
          <h2>
            Welcome to <span>Bhansa Ghar</span>
          </h2>
          <p>
            Bhansa Ghar, a culinary sanctuary, brings the vibrant flavors of
            Mithila to your plate. Our menu showcases traditional delights like
            Kumrohi, Aduri, and Aam ka Achar, offering a journey into the heart
            of Mithila's rich culinary heritage. Indulge in the essence of our
            region with Pooja Samagiri essentials such as Kush, Janeu, Paag, and
            Dhoti. At Bhansa Ghar, each dish is a carefully crafted tale,
            weaving tradition and innovation into a delightful experience. Join
            us and savor the authentic taste of Mithila at Bhansa Ghar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
