import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from '../Assets/ll-removebg-preview.png'
const Header = () => {
    const [isHover1, setHover1] = useState(false);
    const [isHover2, setHover2] = useState(false);
    const [cartNumber, setCartNumber] = useState(0);

    const [isMobileopen,setIsMobileopen] = useState(false)
    // const [isMobileclose,setisMobileclose] = useState
  
    const handleHover1 = () => {
      setHover1(!isHover1);
      setHover2(false); // Close other dropdown if open
    };
  
    const handleHover2 = () => {
      setHover2(!isHover2);
      setHover1(false); // Close other dropdown if open
    };

    const handleOpen = () =>{
       setIsMobileopen(!isMobileopen)
    }
    const handleClose = ()=>{
      setIsMobileopen(false)

    }

    useEffect(() => {
        const countNumber = localStorage.getItem('cart');
        if (countNumber) {
          const values = JSON.parse(countNumber).length;
          setCartNumber(values);
        }
      }, []);

  return (
    <div className="header-conatiner">
      <di className="main-header">
        <div className="logo">
          
            <Link to={'/home'}><img src={logo} alt="" /></Link>
        </div>
        <div className={`links-nav ${isMobileopen ? "show": ''}`}>
          <ul  className={` ${isMobileopen ? "show": ''}main-navlink`} >
            <li onClick={handleClose}>
              <Link to="/">Home</Link>
            </li>
            <li onClick={handleClose}>
              <Link to="/about">About</Link>
            </li>

            <li onClick={handleClose} className="relate">
              <Link className="hoverhoja" to='/mithila'  onClick={handleHover1} >Mithila Product</Link>
              
            </li>
            <li onClick={handleClose} className="relate">
              <Link className="hoverhoja" to='/Poojasamagiri' onClick={handleHover2} >Pooja Samagiri</Link>
             
            </li>

          </ul>
        </div>

            <div className="mycart">
            <Link to={'/cart'} className="flex">
                {" "}
                <i className="ri-shopping-cart-fill"></i>
              </Link>

            </div>

        <div className="responsive">

          <ion-icon
            className="menu"
              onClick={handleOpen}
            name="menu-outline"
          ></ion-icon>
          <ion-icon
            className="menu-close"
            //   onClick={handleClose}
            name="close-circle-outline"
          ></ion-icon>
        </div>
      </di>
    </div>
  );
};

export default Header;
