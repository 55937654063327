import React, { useEffect, useState } from "react";
import './Cart.css';
import { toast } from 'react-hot-toast';

const Cart = () => {
  const [cartNumber, setCartNumber] = useState(0);
  const [items, setItems] = useState([]);
  const [sumPrice, setSumPrice] = useState(0);

  const calculateTotalPrice = (cartItems) => {
    return cartItems.reduce((total, item) => {
      let itemPrice = 0;

      // Calculate price based on weight in Kg
      if (item.Kg) {
        const weightInKg = parseFloat(item.Kg);
        if (weightInKg === 0.5) {
          itemPrice += item.Price / 2;
        } else {
          itemPrice += item.Price * weightInKg;
        }
      } else if (item.quantity) {
        // Calculate price based on quantity if Kg is not present
        itemPrice += item.Price * item.quantity;
      }

      // Add the price of the current item to the total
      return total + itemPrice;
    }, 0);
  };

  const sendMoney = () => {
    window.location.href = `/Pay-for-order/${sumPrice}`;
  };

  const handleRemove = (index) => {
    console.log("remove hit", index);

    // Get the current items from localStorage
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];

    // Check if the index is valid
    if (index >= 0 && index < cartItems.length) {
      // Remove the item at the specified index
      const removedItem = cartItems.splice(index, 1);

      // Update localStorage and state
      localStorage.setItem('cart', JSON.stringify(cartItems));
      setItems(cartItems);

      // Calculate and update the total price
      const totalPrice = calculateTotalPrice(cartItems);
      setSumPrice(totalPrice);

      console.log("Removed item:", removedItem);
      toast.success("Item Removed");
    } else {
      console.error("Invalid index for item removal");
    }
  };

  useEffect(() => {
    const countNumber = localStorage.getItem("cart");
    if (countNumber) {
      const values = JSON.parse(countNumber).length;
      setCartNumber(values);
      setItems(JSON.parse(countNumber));

      // Calculate and update the total price
      const totalPrice = calculateTotalPrice(JSON.parse(countNumber));
      setSumPrice(totalPrice);
    }
  }, [cartNumber]);

  return (
    <div className="cart-container">
      {items.map((item, index) => (
        <div key={index} className="cart-item">
          <img src={item.img} alt={item.title} />
          <div className="info">
            <h4>{item.title}</h4>
            <p>Price: {item.Price}</p>
            <p>Kg /  Unit: {item.Kg ? item.Kg : item.quantity}</p>
            {/* <p>Quantity: {item.quantity}</p> */}
          </div>
          <div>
            <button className="remove" onClick={() => handleRemove(index)}>Remove</button>
          </div>
        </div>
      ))}
      <div className="total-div">
        <p><b>Total:</b> ₹ {sumPrice}</p>
      </div>
      <button onClick={sendMoney} className="button">
        Pay Now
      </button>
    </div>
  );
};

export default Cart;
