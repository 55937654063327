import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Component/Header/Header';
import Home from './Component/Home/Home';
import Category from './Component/Category/Category';
import Footer from './Component/Footer/Footer';
import About from './Component/About/About';
import Cart from './Component/cart/Cart';
import { Toaster } from 'react-hot-toast';
import Payment from './Component/PaymentPage/Payment';
import Delivery from './Component/Delivery/Delivery';
import Poojasamagiri from './Component/Pooja-samagiri/Poojasamagiri';
import Mithila from './Component/Mithila-product/Mithila';
import Contact from './Component/Contact/Contact';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/' element={<Category />} />
          <Route path='/about' element={<About />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/Pay-for-order/:money' element={<Payment />} />
          <Route path='/Delivery' element={<Delivery />} />
          <Route path='/Poojasamagiri' element={<Poojasamagiri />} />
          <Route path='/mithila' element={<Mithila />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footer/>
        <Toaster/>
      </BrowserRouter>
    </div>
  );
}

export default App;
