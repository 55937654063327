// Mithila.js

import React, { useState } from 'react';
import './Mithila.css';
import { toast } from 'react-hot-toast';

const Mithila = () => {
  const [totalProduct, setTotalProduct] = useState(10);
  const [quantities, setQuantities] = useState(Array(totalProduct).fill(1));
  const [currentPage, setCurrentPage] = useState(1);
  const Mymithila_product = [
    {
      id: 1,
      imageUrls: "https://rukminim2.flixcart.com/image/850/1000/jtq47m80/fryum-papad/e/t/x/350-adauri-gold-urad-daal-badi-ms-501-ada-fryums-mithila-original-imafeyjgjeyagrxz.jpeg?q=90&crop=false",
      title: "Aduri ",
      mrpPrice: 999,
      discountprice: 799,
      detail: 'Bihari Desi Urad Daal Adauri  | उडद दाल की अदौरी |टेस्ट ऑफ बिहार (Taste of Bihar)'
    },
    {
      id: 2,
      imageUrls: "https://img-global.cpcdn.com/recipes/343e20b222c1575b/680x482cq70/%E0%A4%9A%E0%A4%B0%E0%A4%B0-charauri-recipe-in-hindi-%E0%A4%B0%E0%A4%B8%E0%A4%AA-%E0%A4%AE%E0%A4%96%E0%A4%AF-%E0%A4%A4%E0%A4%B8%E0%A4%B5%E0%A4%B0.jpg",
      title: "Charauri",
      mrpPrice: 999,
      discountprice: 799,
      detail: 'चावल से बनाये बिहारी नाश्ता चरौरी | Rice Wafers | टेस्ट ऑफ बिहार (Taste of Bihar)'
    },
    {
      id: 3,
      imageUrls: "https://5.imimg.com/data5/SELLER/Default/2022/11/KV/BX/PX/30525912/naya-dhan-ka-bhagalpuri-premium-original-katarni-chura-poha.jpg",
      title: " Nabka dhaan chura",
      mrpPrice: 1200,
      discountprice: 699,
      detail: 'काला नमक सुगंधित प्रीमियम चूड़ा/पोहा |टेस्ट ऑफ बिहार (Taste of Bihar) '
    },
    {
      id: 4,
      imageUrls: "https://static.toiimg.com/photo/88607572.cms",
      title: "Tilkut",
      mrpPrice: 1100,
      discountprice: 1000,
      detail: 'तिलकुट भारत के बिहार और झारखंड राज्यों में बनाई जाने वाली एक मिठाई है '
    },
    {
      id: 5,
      imageUrls: "https://images.news18.com/ibnkhabar/uploads/2024/01/Chane-Khesari-ka-Saag-Photo-3-2024-01-ef63085120be6a47ec1342238700e9b6.jpg",
      title: "Khesari Saag",
      stock: "Out Of Stock",
      mrpPrice: 1100,
      discountprice: 500,
      detail: 'खेसरी साग एक अत्यधिक पौष्टिक पत्तेदार सब्जी है जो विटामिन फाइबर, कैल्शियम और से भरपूर है'
    },
    {
      id: 6,
      imageUrls: "https://5.imimg.com/data5/SELLER/Default/2023/5/310678376/BM/YS/CS/156531209/1-500x500.jpg",
      title: "Marua aata",
      mrpPrice: 2600,
      discountprice: 2500,
      detail: 'मरुआ का आटा के फायदे: मड़ुआ का आटा खाने से हड्डियाँ मजबूत होती हैं। यह बच्चों और बड़ों दोनों के लिए ही उत्तम आहार है'
    },
    {
      id: 7,
      imageUrls: "https://www.jhajistore.com/cdn/shop/products/MithilaSpecialMango_2048x2048.jpg?v=1693199289",
      title: "Aam Ka Achar",
      mrpPrice: 600,
      discountprice: 500,
      detail: 'यह पारंपरिक भारतीय अचार है जो खेत से हाथ से चुने हुए आमों का उपयोग करके बनाया जाता है'
    },
    {
      id: 8,
      imageUrls: "https://i.ibb.co/Gtdq5Kj/Whats-App-Image-2024-02-14-at-6-12-02-AM.jpg",
      title: " Kumhrauri",
      mrpPrice: 700,
      discountprice: 600,
      detail: ' कुम्हरौरी | कद्दू से बनी बड़ी | पेठे की बड़ी | मिथिला विशेष भोजन कुम्हरौरी | बिहार की स्वादिष्टता'
    },
    {
      id: 9,
      imageUrls: "https://i.ibb.co/tLRHVFX/kk.png",
      title: "Arikanchan patta ",
      mrpPrice: 30,
      discountprice: 25,
      detail: 'अरिकंचन पत्ता बिहार का एक लोकप्रिय स्नैक व्यंजन है जो अरबी के पत्तों और बेसन से बनाया जाता है।'
    },

    {
      id: 10,
      imageUrls: "https://i.ibb.co/TRnkZVm/ff.png",
      title: "Bathua saag",
      mrpPrice: 600,
      discountprice: 500,
      detail: 'बथुआ साग एक अत्यधिक पौष्टिक पत्तेदार सब्जी है जो विटामिन फाइबर, कैल्शियम और से भरपूर है'
    },
  ]
    const productsPerPage = 8;
  const totalPage = Math.ceil(Mymithila_product.length / productsPerPage);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = Mymithila_product.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleNumber = (e, index) => {
    const value = parseInt(e.target.value, 10);

    if (isNaN(value) || value < 500 || value > 10000) {
      console.error('Invalid quantity input');
    } else {
      setQuantities(prevQuantities => {
        const newQuantities = [...prevQuantities];
        newQuantities[index] = value;
        return newQuantities;
      });
    }
  };

  const handleTocart = (id, weight, count) => {
    if (id && count !== undefined) {
      const selectedProduct = Mymithila_product.find((product) => product.id === id);
      const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

      const existingProductIndex = existingCart.findIndex(
        (item) => item.productId === id
      );

      if (existingProductIndex !== -1) {
        existingCart[existingProductIndex].quantity += count;
        toast.success("Item Already Present In The Cart We increase the Quantity")

      } else {
        const newProduct = {
          productId: id,
          title: selectedProduct.title,
          Price: selectedProduct.discountprice,
          img: selectedProduct.imageUrls,
          Kg: weight,
          quantity: count,
        };

        existingCart.push(newProduct);
      }
      toast.success("Item Added To Cart")
      localStorage.setItem("cart", JSON.stringify(existingCart));
      console.log(existingCart);
    } else {
      console.error("Invalid id or count is undefined");
      toast.error("Item Added To Cart")

    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="mithila">
      <h1>Mithila Food</h1>
      <div className="mithila-container">
        {currentProducts.map((item, index) => (
          <div key={index} className="mithila-item">
            <div className="mithila-img">
              <img src={item.imageUrls} alt="img" />
            </div>
            <div className="mithila-detail">
              <h4>{item.title}</h4>
              <div><p>{item.detail}</p></div>
              <p className='price'>
                <del>₹{item.mrpPrice}</del>
                ₹{item.discountprice} per kg
              </p>
              <p className='outOfStock'>{item.stock || ""}</p>
              <div className="mithila-select-container">
                <select
                  className="mithila-select"
                  value={quantities[index] || ''}
                  onChange={(e) => handleNumber(e, index)}
                  name="quantity"
                >
                  <option value="" key="">---select Your Weight ---</option>
                  {[...Array(19)].map((_, i) => (
                    <option
                      key={i}
                      className="mithila-option"
                      value={(i + 1) * 500}
                    >
                      {(i + 1) * 500}g
                    </option>
                  ))}
                </select>
              </div>
              <div className="mithila-add">
                <button onClick={() => handleTocart(item.id, ((quantities[index] || 500) / 1000) + 'kg', quantities[index])}>
                  <i className="ri-shopping-bag-fill"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination-btn">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPage}
        >
          Next
        </button>
      </div>
    </div>
  )
};

export default Mithila;
