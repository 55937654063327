import React from "react";
import { Link, useParams } from "react-router-dom";
import './pY.css'

const Payment = () => {
  const { money } = useParams();
  return (
    <div className="payment-order">
      <div className="pay-for">
       <p> Payment {money}</p>
        <div className="money-access">
          <h1>Scan this to pay through PhonePe</h1>
          <h2>Shipping Charges Extra Depend On Location ...</h2>
          <div className="images">

          <img src="https://i.ibb.co/jvb5bsG/image.png" className="object-cover" alt="" />
          </div>
        </div>
        <button class="button"><Link to="/delivery">Done Payment</Link></button>
      </div>
    </div>
  );
};

export default Payment;
