import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-container'>
            <footer>
                <div className='footer-top'>
                    <div className='footer-left'>
                        <h1>Bhansa <span>Ghar</span></h1>

                        <p>
                        Bhansa Ghar, a culinary sanctuary, brings the vibrant flavors of
            Mithila to your plate. Our menu showcases traditional delights like
            Kumrohi, Aduri, and Aam ka Achar, offering a journey into the heart
            of Mithila's rich culinary heritage.
                        </p>

                        <div className='footer-icon'>
                            <div><Link to={'https://www.instagram.com/bhansaaghar01/'} ><i class="ri-instagram-line"></i></Link></div>
                            <div><Link to={'https://www.facebook.com/profile.php?id=61552918737279'} ><i class="ri-facebook-line"></i></Link></div>
                         
                        </div>
                    </div>
                    <div className='footer-right'>
                            <ul>
                                <p>About Us</p>
                                <li><Link to={'/'}>Pivicy Policey</Link></li>
                                <li><Link to={'/'}>Terms </Link></li>
                                <li><Link to={'/'}>Condition </Link></li>
                            </ul>
                            <ul>
                                <p>Delivery</p>
                                <li><Link to={'/cart'}>Cart of delivery</Link></li>
                                <li><Link to={'/'}>Payment Method</Link></li>
                                <li><Link to={'/'}>Delivery Access </Link></li>
                            </ul>
                            <ul>
                                <p>Company</p>
                                <li><Link to={'/about'}>About</Link></li>
                                <li><Link to={'/mithila'}>Mithila Food Product</Link></li>
                                <li><Link to={'/Poojasamagiri'}>Pooja Samagiri</Link></li>
                                <li><Link to={'/contact'}>Contact </Link></li>
                            </ul>
                    </div>
                </div>
            </footer>
        </div>
        <div className='footer-bottom'>
                    <h4>@2024 All Right Reserved By <span><Link to={'https://dgmt.in/'}>DGMT</Link></span></h4>
                </div>
    </div>
  )
}

export default Footer