import React from 'react'
import './Home.css'
import Hero from '../Hero/Hero'
import ProductPage from '../product/Product'
import Product from '../product/Product'
import About from '../About/About'
import Contact from '../Contact/Contact'
import Mithila from '../Mithila-product/Mithila'

const Home = () => {
  return (
    <>
       <Hero/>
       <About/>
       <Mithila  />
       {/* <Product/> */}
       <Contact/>
    </>
  )
}

export default Home