import React from 'react'
import './Hero.css'
import chat from '../Assets/3784.jpg'

const Hero = () => {
  return (
    <>
        <section className='hero'>
            <div className="container">
                <div className="hero-text">
                    <h1>Order Your <br /> Dairy Groceries</h1>
                    <div>
                        <input type="email" placeholder='Enter Your Email' />
                        <button>Subscribe</button>
                    </div>
                </div>

                <div className="hero-img">
                    {/* <img src={chat} alt="" /> */}
                </div>
            </div>
        </section>
    </>
  )
}

export default Hero