import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
   <div className='pro'>
        <div class="contact-container">
    <div class="content">
      <div class="left-side">
        <div class="address details">
        <i class="ri-map-pin-fill"></i>
          <div class="topic">Address</div>
          <div class="text-one">Plot No.7 1st Floor Khasra No:412</div>
          <div class="text-two">Sukhlal Market Pitampura Delhi-34</div>
        </div>
        <div class="phone details">
        <i class="ri-phone-fill"></i>
          <div class="topic">Phone</div>
          <div class="text-one">+91 9958969632</div>
        </div>
        <div class="email details">
        <i class="ri-mail-fill"></i>
          <div class="topic">Email</div>
          <div class="text-one">mithilaproducts9891@gmail.com</div>
        </div>
      </div>
      <div class="right-side">
        <div class="topic-text">Send us a message</div>
        <p>If you have any work from me or any types of quries related to my tutorial, you can send me message from here. It's my pleasure to help you.</p>
      <form action="#">
        <div class="input-box">
          <input type="text" placeholder="Enter your name"/>
        </div>
        <div class="input-box">
          <input type="text" placeholder="Enter your email" />
        </div>
        <div class="input-box message-box">
          <textarea placeholder='Enter Your Message'></textarea>
        </div>
        <div class="btn">
          <input type="button" value="Send Now" />
        </div>
      </form>
    </div>
    </div>
  </div>
   </div>
  )
}

export default Contact